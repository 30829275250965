export class AddressHelper {
    static getUnbreakableAddress = (address: string): string => {
        const parts = address.split(",");
        const unbreakableParts = parts.map(part => part
            .trim()
            .replace(" ", "\u00A0")
        );
        return unbreakableParts.join(", ");
    }
}
