import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import HotelPageMaxWidth from "../HotelPageMaxWidth";
import Title from "../../../components/Title/Title";
import HourlyObjectsList from "../../../components/HourlyObjectsList/HourlyObjectsList";

const HourlyObjects = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <HotelPageMaxWidth>
            <Title id="services">{t("hourlyRental")}</Title>
            <HourlyObjectsList />
        </HotelPageMaxWidth>
    );
};

HourlyObjects.displayName = "HourlyObjects";
export default HourlyObjects;
