import {useSelector} from "react-redux";
import styles from "./HotelContacts.scss";
import {AddressHelper} from "../../../helpers/AddressHelper";
import {ContactType, IContact} from "../../../data/HotelInfo";
import {IAppState} from "../../../store/AppState";
import HotelPageMaxWidth from "../HotelPageMaxWidth";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import HotelContactsItem from "./HotelContactsItem";
import {LogoViberIcon16Regular} from "@skbkontur/icons/LogoViberIcon16Regular";
import {LogoVkIcon16Solid} from "@skbkontur/icons/LogoVkIcon16Solid";
import {LogoTelegramIcon16Regular} from "@skbkontur/icons/LogoTelegramIcon16Regular";
import {LogoWhatsappIcon16Regular} from "@skbkontur/icons/LogoWhatsappIcon16Regular";
import {TechPhoneClassicIcon16Solid} from "@skbkontur/icons/TechPhoneClassicIcon16Solid";
import {LocationPinIcon16Solid} from "@skbkontur/icons/LocationPinIcon16Solid";

const getContactsByType = (contacts: IContact[], type: ContactType): string[] => (
    contacts?.length
        ? contacts.filter(c => c.type === type).map(p => p.value)
        : []
);

const HotelContacts = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {address, contacts} = useSelector((state: IAppState) => state.hotelInfo.info);

    return (
        <HotelPageMaxWidth>
            <span className={styles.title}>{t("contacts")}</span>
            <div className={styles.container}>
                <HotelContactsItem
                    values={[AddressHelper.getUnbreakableAddress(address)]}
                    icon={<LocationPinIcon16Solid />}
                    type={ContactType.Address}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Phone)}
                    icon={<TechPhoneClassicIcon16Solid />}
                    type={ContactType.Phone}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Email)}
                    icon={<span className={styles.icon}>@</span>}
                    type={ContactType.Email}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Whatsapp)}
                    icon={<LogoWhatsappIcon16Regular />}
                    type={ContactType.Whatsapp}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Telegram)}
                    icon={<LogoTelegramIcon16Regular />}
                    type={ContactType.Telegram}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Vk)}
                    icon={<LogoVkIcon16Solid />}
                    type={ContactType.Vk}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Viber)}
                    icon={<LogoViberIcon16Regular />}
                    type={ContactType.Viber}
                />
            </div>
        </HotelPageMaxWidth>
    );
};
HotelContacts.displayName = "HotelContacts";
export default HotelContacts;
