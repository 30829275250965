import {detect} from "detect-browser";

export class BrowserHelper {
    static detect = () => detect();

    static isIE = () => {
        const browser = detect();
        return browser?.name === "ie";
    };
}
