import PageNotFoundImage from "../../images/PageNotFound.svg";
import styles from "./HotelNotFound.scss";
import WidgetStore from "../../widget/WidgetStore";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

export const HotelNotFound = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <div className={styles.root}>
            <img src={WidgetStore.getUrlWithBase(PageNotFoundImage)} alt="404" className={styles.image} />
            <div className={styles.textBlock}>
                <div className={styles.caption}>{t("HotelNotFound.caption")}</div>
                <div className={styles.text}>{t("HotelNotFound.text")}</div>
            </div>
        </div>
    );
};
