import {IPlaceSearchParams} from "../../../data/SearchParams";
import styles from "./HotelsSearch.scss";
import MainPageLogoImage from "../../../images/MainPageLogo.svg";
import Globe from "../../../images/Globe.svg";
import {Autocomplete} from "@skbkontur/react-ui";
import {tooltip, ValidationContainer, ValidationInfo, ValidationWrapper} from "@skbkontur/react-ui-validations";
import hotelSearchApi from "../../../api/hotelSearch";
import {DebouncePromise} from "../../../common/helpers/AsyncHelper";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import DatesPeriodControl, {IDatePeriodValidationInfo} from "../../../components/DatesPeriodControl/DatesPeriodControl";
import {hotelSearch} from "../../../store/hotelsSearch/actions";
import {LanguageSelect, tType, useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import GuestsSelectControl from "../../../components/GuestsSelectControl/GuestsSelectControl";
import {PlaceSearchParamsContext} from "../../../providers/SearchParams/SearchParamsContext";
import {mainSearchAnalyticsEvents} from "../../../analytics/mainSearchAnalyticsEvents";
import DateHelper from "../../../helpers/DateHelper";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import {useMount} from "@skbkontur/hotel-hooks/react";

export const SearchHeader = (tInBookingModule: tType) => (
    tInBookingModule("MainPage.hotelSearch", {stringFormat: true})
);

const HotelsSearch = () => {
    const {t, tcn, lng} = useTranslation(TranslationNamespaces.BookingModule);
    const dispatch = useDispatch();

    const isLoading = useSelector((state: IAppState) => state.hotelsSearch.isLoading);

    const {params, setParams} = React.useContext(PlaceSearchParamsContext);
    const {place, fromDate, toDate, adultsCount, kidsCount} = params;

    const validationContainerRef = React.useRef<ValidationContainer>();
    const datesPeriodControlRef = React.useRef<DatesPeriodControl>();

    const datesPeriodValidationInfoRef = React.useRef<IDatePeriodValidationInfo>();
    const guestsCountValidationInfoRef = React.useRef<ValidationInfo>();
    const placeValidationInfoRef = React.useRef<ValidationInfo>();

    const handleDatesPeriodValidation = (validationInfo: IDatePeriodValidationInfo) => datesPeriodValidationInfoRef.current = validationInfo;
    const handleGuestsCountValidation = (validationInfo: ValidationInfo) => guestsCountValidationInfoRef.current = validationInfo;

    const focusFirstEmptyPicker = () => datesPeriodControlRef.current?.focusFirstEmptyPicker();

    const isValidationCorrect = (): boolean => (
        !guestsCountValidationInfoRef.current
        && !datesPeriodValidationInfoRef.current?.fromDatePicker
        && !datesPeriodValidationInfoRef.current?.toDatePicker
        && !placeValidationInfoRef.current
    );

    const handleSearchRequest = () => (
        validationContainerRef.current.validate().then(isValid => {
            if (isValid) {
                mainSearchAnalyticsEvents.trackSearchFormRunSearch();
                dispatch(hotelSearch(params));
            }
        })
    );

    useMount(() => {
        isValidationCorrect() && handleSearchRequest();
    });

    const validatePlace = (place: string): ValidationInfo => {
        const minPlaceLength = 3;
        if (!place) return createValidationInfo(t("HotelSearch.placePlaceholder"));
        if (place.length < minPlaceLength)
            return createValidationInfo(tcn("validations.minLength", {count: minPlaceLength}));
        return null;
    };

    const debouncedSuggest = DebouncePromise((place: string) => (
        !validatePlace(place) ? hotelSearchApi.suggest(place) : Promise.resolve([] as string[])
    ), 300);

    const handleChangeParams = (paramsPartial: Partial<IPlaceSearchParams>) => {
        setParams((params) => ({
            ...params,
            ...paramsPartial
        }));
    };
    const handlePlaceChange = (place: string) => handleChangeParams({place});
    const handleFromDateChange = (fromDate: string) => {
        const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);
        mainSearchAnalyticsEvents.trackSearchFormDatePeriodChoose(nightsCount);
        handleChangeParams({fromDate});
    };
    const handleToDateChange = (toDate: string) => {
        const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);
        mainSearchAnalyticsEvents.trackSearchFormDatePeriodChoose(nightsCount);
        handleChangeParams({toDate});
    };
    const handleAdultsCountChange = (adultsCount: number) => {
        mainSearchAnalyticsEvents.trackSearchFormGuestsChange(adultsCount, kidsCount);
        handleChangeParams({adultsCount});
    };
    const handleKidsCountChange = (kidsCount: number) => {
        mainSearchAnalyticsEvents.trackSearchFormGuestsChange(adultsCount, kidsCount);
        handleChangeParams({kidsCount});
    };

    return (
        <div className={styles.container}>
            <ValidationContainer ref={validationContainerRef}>
                <div className={styles.top}>
                    <div className={styles.logo}>
                        <img className={styles.logoIcon} src={MainPageLogoImage}/>
                        <span className={styles.logoTitle}>{SearchHeader(t)}</span>
                    </div>
                    <div className={styles.languageSelect}>
                        <LanguageSelect image={Globe} withArrow/>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.fields}>
                        <div className={styles.fieldsPlace}>
                            <ValidationWrapper
                                validationInfo={placeValidationInfoRef.current = validatePlace(place)}
                                renderMessage={tooltip("top left")}
                            >
                                <Autocomplete
                                    value={place || ""}
                                    size="medium"
                                    width="100%"
                                    placeholder={t("HotelSearch.placePlaceholder", {stringFormat: true})}
                                    onValueChange={handlePlaceChange}
                                    source={debouncedSuggest}
                                />
                            </ValidationWrapper>
                        </div>
                        <div className={styles.fieldsPeriod}>
                            <DatesPeriodControl
                                fromField={{
                                    value: fromDate,
                                    placeholder: tcn("checkin"),
                                    onChange: handleFromDateChange
                                }}
                                toField={{
                                    value: toDate,
                                    placeholder: tcn("checkout"),
                                    onChange: handleToDateChange
                                }}
                                hideDash
                                ref={datesPeriodControlRef}
                                onValidate={handleDatesPeriodValidation}
                            />
                        </div>
                        <div className={styles.fieldsGuests}>
                            <GuestsSelectControl
                                adults={{
                                    count: parseInt((adultsCount || "").toString(), 10) || 2,
                                    onChange: handleAdultsCountChange
                                }}
                                kids={{
                                    count: parseInt((kidsCount || "").toString(), 10) || 0,
                                    onChange: handleKidsCountChange
                                }}
                                onApply={focusFirstEmptyPicker}
                                onValidate={handleGuestsCountValidation}
                                // исправят поведение бронирования с детьми в другой задаче
                                alwaysShowKids={false}
                            />
                        </div>
                    </div>
                    <div className={styles.submit}>
                        <PrimaryButton
                            width="100%"
                            onClick={handleSearchRequest}
                            loading={isLoading}
                        >
                            {t(lng === "kk" ? "Buttons.find" : "Buttons.findHotels")}
                        </PrimaryButton>
                    </div>
                </div>
            </ValidationContainer>
        </div>
    );
};

export default HotelsSearch;
HotelsSearch.displayName = "HotelsSearch";
