import styles from "./CookieConsent.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import Cookies from "js-cookie";

interface ICookieConsentProps {
    fixed?: boolean;
}

const CookieConsent = ({fixed}: ICookieConsentProps) => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const consentCookieName = "cookieConsentShown";
    const consentCookieShownValue = "true";

    const [consentIsShown, setConsentIsShown] = React.useState(Cookies.get(consentCookieName) === consentCookieShownValue);

    const handleAgreeClick = React.useCallback(() => {
            Cookies.set(consentCookieName, consentCookieShownValue, {expires: 364});
            setConsentIsShown(true);
        }, []
    );

    if (consentIsShown)
        return null;

    return (
        <div className={fixed ? styles.containerFixed : styles.container}>
            {t("cookieConsent", {raw: true})}

            <div className={styles.agreeButton}>
                <PrimaryButton 
                    onClick={handleAgreeClick}
                >
                    {t("agree")}
                </PrimaryButton>
            </div>
        </div>
    );
};

CookieConsent.displayName = "CookieConsent";
export default CookieConsent;
