import {ContactType} from "../../../data/HotelInfo";
import styles from "./HotelContacts.scss";

interface IHotelContactsLinkProps {
    value: string;
    type: ContactType
}

const getLink = (type: ContactType, value: string): string => {
    switch (type) {
        case ContactType.Phone:
            return `tel:${value}`;
        case ContactType.Email:
            return `mailto:${value}`;
        case ContactType.Vk:
            return value.includes("http://") || value.includes("https://")
                ? value
                : `https://${value}`;
        case ContactType.Telegram:
            return `https://t.me/${value.replace(/@/,"")}`;
        case ContactType.Whatsapp:
            return `https://wa.me/${value.replace(/\D/g,"")}`;
        case ContactType.Viber:
            return `viber://chat?number=${value.replace(/\D/g,"")}`;
        default:
            return null;
    }
};

const HotelContactsLink = (props: IHotelContactsLinkProps) => {
    const {value, type} = props;

    if (type === ContactType.Address) {
        return <span>{value}</span>;
    }

    return (
        <a href={getLink(type, value)} className={styles.contactLink}>
            {value}
        </a>
    );
};
HotelContactsLink.displayName = "HotelContactsLink";
export default HotelContactsLink;
