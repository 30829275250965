import styles from "./HotelItem.scss";
import {IHotelSearchModel} from "../../../api/hotelSearch";
import {Link} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";

interface IHotelItemProps {
    hotel: IHotelSearchModel;
    onClick: () => void;
}

export const HotelItem = ({hotel, onClick}: IHotelItemProps) => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const imageStyle = hotel.thumbnailUrl
        ? {style: {backgroundImage: `url(${hotel.thumbnailUrl})`}}
        : {};

    return (
        <div key={hotel.alias} className={styles.item}>
            <div className={styles.itemImage}>
                <Link onClick={onClick}>
                <div
                        className={styles.itemImageSrc}
                        {...imageStyle}
                    />
                </Link>
            </div>
            <div className={styles.itemContent}>
                <div className={styles.itemContentPart}>
                    <div className={styles.itemContentName}>{hotel.name}</div>
                    {hotel.address && <div className={styles.itemContentAddress}>{hotel.address}</div>}
                </div>
                {hotel.description && (
                    <div className={styles.itemContentPart}>
                        <div className={styles.itemContentDesc}>{hotel.description}</div>
                    </div>
                )}
                <div className={styles.itemContentPanel}>
                    <PrimaryButton size="large" width={160} onClick={onClick}>
                        {t("Buttons.look")}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
