import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import styles from "./HotelDescription.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {TimeClockIcon16Regular} from "@skbkontur/icons/TimeClockIcon16Regular";

const HotelDescriptionCheckInCheckOutTime = () => {
    const {t} = useTranslation(TranslationNamespaces.Bookings);

    const {checkInTime, checkOutTime} = useSelector((state: IAppState) => state.hotelInfo.info);

    return (
        <div className={styles.infoRow}>
            <TimeClockIcon16Regular color="#4E93C8" />
            <span className={styles.infoRowText}>
                {t("checkinAt", {checkinTime: checkInTime})}, {t("checkoutAt", {checkoutTime: checkOutTime})}
            </span>
        </div>
    );
};
HotelDescriptionCheckInCheckOutTime.displayName = "HotelDescriptionCheckInCheckOutTime";
export default HotelDescriptionCheckInCheckOutTime;
