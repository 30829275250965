import {Spinner, ThemeContext, ThemeFactory} from "@skbkontur/react-ui";
import styles from "./WhiteSpinner.scss";

interface IPaymentByLinkPageSpinner {
    caption: React.ReactNode;
}

const white = "#FFFFFF";

const WhiteSpinner = (props: IPaymentByLinkPageSpinner) => {
    const {caption} = props;
    const theme = ThemeFactory.create({red: white, yellow: white, green: white, brand: white});

    return (
        <ThemeContext.Provider value={theme}>
            <Spinner caption={caption} type="big" className={styles.spinner} />
        </ThemeContext.Provider>
    );
};

export default WhiteSpinner;
WhiteSpinner.displayName = "WhiteSpinner";
