import {useHistory} from "react-router";
import styles from "./MainPage.scss";
import HotelsSearch, {SearchHeader} from "./HotelsSearch/HotelsSearch";
import {IPlaceSearchParams} from "../../data/SearchParams";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import cn from "classnames";
import scroll from "scroll";
// @ts-expect-error TODO Move to new package or use native methods
import scrollDoc from "scroll-doc";
// @ts-expect-error TODO Move to new package or use native methods
import ease from "ease-component";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {UrlParamsExtractor} from "../../helpers/UrlParamsExtractor";
import MainPageFooter from "./Footer/MainPageFooter";
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import {PlaceSearchParamsProvider} from "../../providers/SearchParams/SearchParamsProvider";
import SearchResults from "./SearchResults/SearchResults";

const scrollElement = scrollDoc();

const MainPage = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    document.title = SearchHeader(t);

    const areHotelsNotEmpty = useSelector((state: IAppState) => !!state.hotelsSearch.hotels);

    const {location} = useHistory();
    const params = UrlParamsExtractor.getSearchParams<IPlaceSearchParams>(location);

    const isResultsMode = !!params && areHotelsNotEmpty;

    React.useEffect(() => {
        if (areHotelsNotEmpty) {
            const currentScrollTop = scrollElement.scrollTop;
            const baseDuration = 1000;
            const resultsModContainerHeight = 480;
            const duration = Math.min(
                baseDuration,
                Math.abs((resultsModContainerHeight - currentScrollTop) / resultsModContainerHeight) * baseDuration
            );
            setTimeout(() => scroll.top(scrollElement, resultsModContainerHeight, {ease: ease.outCirc, duration}), 500);
        }
    }, [areHotelsNotEmpty]);

    return (
        <PlaceSearchParamsProvider syncUrlParams>
            <div>
                <div className={cn(styles.container, {
                    [styles.containerWithAnimation]: areHotelsNotEmpty,
                    [styles.containerResultsMode]: isResultsMode,
                    [styles.containerStartMode]: !isResultsMode
                })}>
                    <HotelsSearch />
                </div>
                {areHotelsNotEmpty && <SearchResults />}
                <CookieConsent />
            </div>
            <MainPageFooter />
        </PlaceSearchParamsProvider>
    );
};

MainPage.diplsayName = "MainPage";
export default MainPage;
