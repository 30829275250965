import styles from "./MainPageFooter.scss";
import AgreementLink from "../../../components/AgreementLink/AgreementLink";
import {KonturI18NContext} from "@skbkontur/i18n";
import {Locale} from "@skbkontur/i18n-locales";
import {Kontur, KonturEN} from "@skbkontur/logos";

const MainPageFooter = () => {
    const {locale} = React.useContext(KonturI18NContext);

    const logo = locale === Locale.Russian
        ? <Kontur size={37} color={"#fff"} />
        : <KonturEN size={37} color={"#fff"} />;

    return (
        <footer className={styles.footer}>
            <div>
                {logo}
            </div>
            <div className={styles.footerLink}>
                <AgreementLink />
            </div>
        </footer>
    );
};

MainPageFooter.displayName = "MainPageFooter";
export default MainPageFooter;
