import "../scss/index.scss";
import {Route, Switch} from "react-router";
import AppRoot from "./AppRoot";
import PaymentByLinkPage from "../pages/PaymentByLinkPage/PaymentByLinkPage";
import MainPage from "../pages/MainPage/MainPage";
import HotelPage from "../pages/HotelPage/HotelPage";
import GlobalErrorLightbox from "./GlobalErrorLightbox/GlobalErrorLightbox";
import {tracker} from "@skbkontur/km-tracker";
import {useConstructor} from "../hooks/useConstructor";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import {SentryErrorType, SentryTag} from "@skbkontur/hotel-sentry";
import {getStageENV, isStageLocal} from "@skbkontur/hotel-process-env";
import {trackerENVType} from "@skbkontur/km-tracker/dist/typings/src/resourses/endpoints";
import {SentryMOBTag, SentryMOBType} from "../data/Sentry";

const SentryFallback = () => <ErrorBoundary />;

const App = () => {
    useConstructor(() => {
        tracker.initialize({
            siteID: 154,
            trackerENV: getStageENV() as trackerENVType,
            options: {catchPageVisits: true, catchUnloadEvent: true},
            devMode: isStageLocal()
        });
    });

    return (
        <AppRoot keepDocumentLangUpdated>
            <Sentry.ErrorBoundary
                fallback={SentryFallback}
                beforeCapture={scope => {
                    scope.setTag(SentryMOBTag.MOBType, SentryMOBType.Site);
                    scope.setTag(SentryTag.Type, SentryErrorType.ErrorBoundary);
                }}
            >
                <Switch>
                    <Route exact path="/" component={MainPage} />
                    <Route path={"/payments-by-link/:id"} component={PaymentByLinkPage} />
                    <Route path={"/:siteName"} component={HotelPage} />
                </Switch>
                <GlobalErrorLightbox />
            </Sentry.ErrorBoundary>
        </AppRoot>
    );
};
App.displayName = "App";
export default App;
