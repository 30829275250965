import styles from "./HotelContacts.scss";
import {ContactType} from "../../../data/HotelInfo";
import HotelContactsLink from "./HotelContactsLink";
import {Fragment} from "react/jsx-runtime";

interface IHotelContactsItemProps {
    values: string[];
    icon: React.ReactElement;
    type: ContactType;
}

const HotelContactsItem = (props: IHotelContactsItemProps) => {
    const {values, icon, type} = props;

    if (!values?.length || !values.some(Boolean))
        return null;

    return (
        <div className={styles.contact}>
            <span className={styles.icon}>{icon}</span>
            <span className={styles.text}>
                {values.map((value, index) => (
                    <Fragment key={index}>
                        <HotelContactsLink type={type} value={value} />
                        {index !== values.length - 1 && <>, </>}
                    </Fragment>
                ))}
            </span>
        </div>
    );
};
HotelContactsItem.displayName = "HotelContactsItem";
export default HotelContactsItem;
